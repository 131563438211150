var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLoading,
              expression: "isLoading"
            }
          ]
        },
        [_vm._t("loading")],
        2
      ),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("h4", { staticClass: "total-contributions" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.totalContributions) +
                " total contributions in the last 12 months\n    "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("calendar-heatmap", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading,
            expression: "!isLoading"
          }
        ],
        attrs: {
          locale: _vm.locale,
          "no-data-text": _vm.locale.no_contributions,
          "tooltip-unit": _vm.locale.contributions,
          "end-date": _vm.endDate,
          values: _vm.values,
          "range-color": _vm.colorRange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }