import { render, staticRenderFns } from "./ActivityHeatmap.vue?vue&type=template&id=4638f316&scoped=true&"
import script from "./ActivityHeatmap.vue?vue&type=script&lang=js&"
export * from "./ActivityHeatmap.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4638f316",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/go/src/code.gitea.io/gitea/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4638f316')) {
      api.createRecord('4638f316', component.options)
    } else {
      api.reload('4638f316', component.options)
    }
    module.hot.accept("./ActivityHeatmap.vue?vue&type=template&id=4638f316&scoped=true&", function () {
      api.rerender('4638f316', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "web_src/js/components/ActivityHeatmap.vue"
export default component.exports