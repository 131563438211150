var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", {
        ref: "style",
        staticClass: "activity-bar-graph",
        staticStyle: { width: "0px", height: "0px" }
      }),
      _vm._v(" "),
      _c("div", {
        ref: "altStyle",
        staticClass: "activity-bar-graph-alt",
        staticStyle: { width: "0px", height: "0px" }
      }),
      _vm._v(" "),
      _c("vue-bar-graph", {
        attrs: {
          points: _vm.graphData,
          "show-x-axis": true,
          "show-y-axis": false,
          "show-values": true,
          width: _vm.graphWidth,
          "bar-color": _vm.colors.barColor,
          "text-color": _vm.colors.textColor,
          "text-alt-color": _vm.colors.textAltColor,
          height: 100,
          "label-height": 20
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function(opt) {
              return _vm._l(_vm.authors, function(author, idx) {
                return _c("g", { key: author.position }, [
                  opt.bar.index === idx && author.home_link !== ""
                    ? _c("a", { attrs: { href: author.home_link } }, [
                        _c("image", {
                          attrs: {
                            x: opt.bar.midPoint - 10 + "px",
                            y: opt.bar.yLabel + "px",
                            height: "20",
                            width: "20",
                            href: author.avatar_link
                          }
                        })
                      ])
                    : opt.bar.index === idx
                    ? _c("image", {
                        attrs: {
                          x: opt.bar.midPoint - 10 + "px",
                          y: opt.bar.yLabel + "px",
                          height: "20",
                          width: "20",
                          href: author.avatar_link
                        }
                      })
                    : _vm._e()
                ])
              })
            }
          },
          {
            key: "title",
            fn: function(opt) {
              return _vm._l(_vm.authors, function(author, idx) {
                return _c("tspan", { key: author.position }, [
                  opt.bar.index === idx
                    ? _c("tspan", [_vm._v(_vm._s(author.name))])
                    : _vm._e()
                ])
              })
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }